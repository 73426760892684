.Slide {
  /* display: inline-flex; */
  display: flex;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  background-color: white;
  color: #818085;
}

.WideImageThenCopyRowSlide {
}

.Header {
  background-color: #162f57;
  color: #ffffff;
}

.Footer {
  background-color: #162f57;
  color: #ffffff;
}

.Slide.BlueTheme {
  background-color: #162f57;
  color: #ffffff;
}

.Slide h1 {
  font-size: 1.25em;
  text-align: center;
  color: #008dcb;
}

.Slide.BlueTheme h1 {
  color: white;
}

.Slide h2 {
  font-size: 1em;
  color: #62708a;
}

.Slide p {
  font-size: 15px;
}

.Slide .Attention {
  font-weight: bold;
  color: #0093cd;
}

.Slide .Instruction {
  color: #ffa700;
}

.SlideContent {
  flex: 1;
}

.Title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeroImage {
  flex: 1;
  width: 100%;
  height: 40vh;
  /* max-height: 40vh; */
  display: block;
  object-fit: cover;
  object-position: left top;
}

.HeroSlide h1 {
  font-size: 1.5em;
}

.SlideImage {
  display: block;
  width: 100%;
  height: 35vh;
  object-fit: cover;
}

.SlideWideImage {
  display: block;
  min-width: 100%;
  max-height: 35vh;
  object-fit: cover;
}

div.SlideImageCol {
  padding: 0;
  width: 100%;
}

div.SlideWideImageCol {
  overflow: scroll;
}

.SlideCopy {
  padding-top: 2em;
}

.FinishModal {
  color: black;
}

@media (orientation: landscape) {
  .SlideWideImage {
    max-height: 45vh;
  }
  .Slide-QuestionsIntro .SlideWideImage {
    max-height: 30vh;
  }

  .WideImageThenCopyRowSlide .SlideCopy {
    padding-top: unset;
  }
}

/* Small devices (bootstrap sm breakpoint) */
@media (min-width: 576px) {
  .SlideContent {
    flex: unset;
  }
  .Footer {
    flex: 1;
  }
  .SlideImage {
    height: unset;
    max-height: 70vh;
  }
}
/* Medium devices (bootstrap md breakpoint) */
@media (min-width: 768px) {
  .Slide {
    margin: auto;
    max-width: 768px;
    width: 768px;
    max-height: 570px;
    min-height: 570px;
  }
  .SlideContent {
    height: 500px;
  }
  .SlideImage {
    overflow: hidden;
    height: 500px;
  }
  .SlideWideImage {
    width: 100%;
  }
  div.SlideWideImageCol {
    overflow: auto;
  }
}
/* Large devices (bootstrap lg breakpoint) */
@media (min-width: 992px) {
}
/* Extra large devices (bootstrap xl breakpoint) */
@media (min-width: 1200px) {
}
