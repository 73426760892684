.ContactData {
  margin: 20px auto;
  /* width: 90%; */
  text-align: center;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 10px;
  box-sizing: border-box;
}

.ContactData .Container {
  padding: 0;
}

.Input {
  display: block;
}
@media (min-width: 600px) {
  .ContactData {
    /* width: 500px; */
  }
}
